<template>
    <div style="padding:10px;">

        <van-form @submit="onSubmit" :label-width=60>
            <van-field readonly clickable name="domain" :value="domain.text" label="市场" placeholder="点击选择市场"
                @click="showPicker = true" />

            <div style="margin: 20px;">
                <van-button round block type="info" native-type="onSubmit" :disabled='!domain.value'>
                    确认
                </van-button>
            </div>
        </van-form>

        <van-popup v-model="showPicker" round position="bottom">
            <van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>

    </div>
</template>

<script>

    import { Form, Field, Popup, Picker, Button, } from "vant";

    export default {
        components: {
            vanForm: Form,
            vanField: Field,
            vanPopup: Popup,
            vanPicker: Picker,
            vanButton: Button,
        },
        data() {
            return {
                domain: {
                    text: null,
                    value: null,
                },
                showPicker: false,
                columns: [],
            }
        },
        mounted() {
            this.getUserInfo();
        },
        methods: {
            onSubmit() {
                this.$store.dispatch('CHANGE_DOMAIN', this.domain.value)

                if (this.$route.query.redirect) {
                    this.$router.replace(
                        {
                            path: this.$route.query.redirect
                        }
                    )
                } else {
                    this.$router.replace(
                        {
                            path: '/'
                        }
                    )
                }
            },
            onConfirm(value) {
                this.domain = value;
                this.showPicker = false;
            },
            getUserInfo() {
                this.http.get(this.ports.user.info, {
                }, res => {
                    console.log(res);

                    this.http.get(this.ports.user.domain, {
                    }, data => {
                        console.log(data);

                        this.columns = [];
                        for (var i = 0; i < res.domains.length; i++) {
                            for (var j = 0; j < data.domains.length; j++) {
                                if (res.domains[i] === data.domains[j].code) {
                                    this.columns.push(
                                        {
                                            text: data.domains[j].description,
                                            value: data.domains[j].code,
                                        }
                                    )
                                }
                            }
                        }


                    }, err => {
                        console.log(err);
                    })


                }, err => {
                    console.log(err);
                })
            },

        },
    };
</script>